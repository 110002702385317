import { useEffect, useRef, useState } from "react";
import ChartContainer from "../ChartContainer/ChartContainer";
import {
  AdapterMoment,
  Box,
  Button,
  CachedIcon,
  CircularProgress,
  CustomAlert,
  DatePicker,
  FormControl,
  LoadingButton,
  LocalizationProvider,
  SearchIcon,
  TextField,
  Typography,
  styled,
} from "@enerbit/base";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  BarElement,
  Title,
  Tooltip,
  ChartOptions,
  ChartData,
} from "chart.js";
import { Bar, getElementAtEvent } from "react-chartjs-2";
import moment from "moment";
import { DATASETS, DATE_TIME_FORMAT } from "../../constants/orders";
import { getOrdersByType } from "../../services";
import { formatOrdersByType, toCapitalize } from "../../helpers";
import { ChartLegend, OrderGroups } from "../../models/orders";
import DetailModal from "./DetailModal";
import ChartDataLabels from "chartjs-plugin-datalabels";
import useMediaQuery from "../../hooks/useMediaQuery";
import { MOBILE_BREAKPOINT } from "../../constants/common";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  LogarithmicScale,
  ChartDataLabels
);

const options: ChartOptions<"bar"> = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
    },
    datalabels: {
      display: false,
      color: "white",
      anchor: "center",
      align: "center",
      formatter: (value) => {
        return !isNaN(value) && value !== 0 ? value : null;
      },
    },
  },
  scales: {
    y: {
      title: { display: true, text: "N° de órdenes", font: { family: "Helvetica Neue LT Std" } },
      type: "logarithmic",
    },
    x: { title: { display: true, text: "Mes", font: { family: "Helvetica Neue LT Std" } } },
  },
};

const DEFAULT_SINCE = moment().subtract(3, "month").startOf("month").format(DATE_TIME_FORMAT);
const DEFAULT_UNTIL = moment().endOf("month").format(DATE_TIME_FORMAT);

const INDEX_TO_GROUPS: Record<number, OrderGroups> = {
  0: OrderGroups.INSTALLATION,
  1: OrderGroups.INSPECTION,
  2: OrderGroups.NORMALIZATION,
  3: OrderGroups.SUSPENSION_RECONNECTION,
  4: OrderGroups.CANCELATION,
};

const INDEX_TITLES: Record<number, string> = {
  0: "Instalaciones",
  1: "Inspecciones",
  2: "Normalizaciones",
  3: "Suspensiones y reconexiones",
  4: "Cancelaciones",
};

interface CustomLegend extends ChartLegend {
  fontColor: string;
}

const OrdersByType = () => {
  const [legends, setLegends] = useState<CustomLegend[]>([
    { label: "Instalación", backgroundColor: "#EFE9FB", fontColor: "#7D3EFB", hidden: false },
    { label: "Inspección", backgroundColor: "#E0F2FF", fontColor: "#3892F3", hidden: false },
    { label: "Normalización", backgroundColor: "#E7F8F0", fontColor: "#12B76A", hidden: false },
    {
      label: "Suspensión/Reconexión",
      backgroundColor: "#FEF4E6",
      fontColor: "#F79009",
      hidden: false,
    },
    { label: "Cancelación", backgroundColor: "#FEECEB", fontColor: "#F04438", hidden: false },
  ]);

  const [since, setSince] = useState<string>(DEFAULT_SINCE);
  const [until, setUntil] = useState<string>(DEFAULT_UNTIL);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [datesError, setDatesError] = useState<string | undefined>("");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [detailedOrders, setDetailedOrders] = useState<Record<string, any> | undefined>(undefined);
  const [modalData, setModalData] = useState<Record<string, any> | undefined>(undefined);
  const [modalTitle, setModalTitle] = useState<string>("");

  const [chartData, setChartData] = useState<ChartData<"bar", number[], string>>({
    datasets: [],
    labels: [],
  });

  const isTablet = useMediaQuery(MOBILE_BREAKPOINT);

  const chartRef = useRef<any>(null);

  const handleLegendClick = (datasetIndex: number) => {
    const chart = chartRef.current;
    chart.getDatasetMeta(datasetIndex).hidden =
      chart.getDatasetMeta(datasetIndex).hidden === null
        ? true
        : !chart.getDatasetMeta(datasetIndex).hidden;
    setLegends((prev) => {
      const nD = [...prev];
      nD[datasetIndex].hidden = chart.getDatasetMeta(datasetIndex).hidden;
      return nD;
    });
    chart.update();
  };

  const fetchOrders = async (tSince: string, tUntil: string) => {
    if (!tSince || !tUntil) return;

    if (moment(tSince).isAfter(tUntil)) {
      setDatesError("La fecha inicial no puede ser mayor que la fecha final.");
      return;
    }

    if (moment(tUntil).isBefore(tSince)) {
      setDatesError("La fecha final no puede ser menor que la inicial.");
      return;
    }

    setLegends((prev) => {
      const nD = prev.map((legend) => ({ ...legend, hidden: false }));
      return nD;
    });

    setDatesError(undefined);
    setLoading(true);

    try {
      const tOrders = await getOrdersByType(tSince, tUntil);
      const { sortedTotals, sortedDetail } = formatOrdersByType(tOrders);
      setDetailedOrders(sortedDetail);

      setChartData({
        labels: Object.keys(sortedTotals).map((key) =>
          toCapitalize(moment(key).format("MMMM YYYY"))
        ),
        datasets: DATASETS.map((data) => ({
          label: data.label,
          backgroundColor: data.backgroundColor,
          borderRadius: 4,
          data: Object.keys(sortedTotals).map((key) => sortedTotals[key][data.dataKey]),
        })),
      });
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleRefresh = () => {
    fetchOrders(DEFAULT_SINCE, DEFAULT_UNTIL);
    setSince(DEFAULT_SINCE);
    setUntil(DEFAULT_UNTIL);
  };

  const handleElementClick = (event: React.MouseEvent<HTMLCanvasElement>) => {
    setOpenModal(true);
    const selectedItem = getElementAtEvent(chartRef.current, event);

    if (selectedItem.length === 0) return;

    const datasetIndex = selectedItem[0].datasetIndex;
    const index = selectedItem[0].index;

    if (detailedOrders) {
      setModalData(Object.values(detailedOrders)[index][INDEX_TO_GROUPS[datasetIndex]]);
      setModalTitle(
        `${INDEX_TITLES[datasetIndex]} ${moment(Object.keys(detailedOrders)[index]).format(
          "MMMM YYYY"
        )}`
      );
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setModalData(undefined);
  };

  useEffect(() => {
    fetchOrders(DEFAULT_SINCE, DEFAULT_UNTIL);
  }, []);

  return (
    <>
      <Box
        mb={1}
        sx={{
          display: isTablet ? "block" : "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Box sx={{ width: "35%" }}>
            <FormControl fullWidth sx={{ mr: 1 }}>
              <Typography>Desde</Typography>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  format='MMMM, YYYY'
                  value={moment(since)}
                  onChange={(value: any) => {
                    setSince(moment(value).format(DATE_TIME_FORMAT));
                  }}
                  views={["year", "month"]}
                  openTo='year'
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: false,
                      sx: {
                        borderRadius: "12px",
                        ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "12px",
                        },
                        ".MuiInputBase-root": {
                          height: "41px",
                        },
                      },
                    },
                  }}></DatePicker>
              </LocalizationProvider>
            </FormControl>
          </Box>
          <Box sx={{ width: "35%" }}>
            <FormControl fullWidth sx={{ mr: 1 }}>
              <Typography>Hasta</Typography>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  format='MMMM, YYYY'
                  value={moment(until)}
                  onChange={(value: any) => {
                    setUntil(moment(value).endOf("month").format(DATE_TIME_FORMAT));
                  }}
                  views={["year", "month"]}
                  openTo='year'
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: false,
                      sx: {
                        borderRadius: "12px",
                        ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "12px",
                        },
                        ".MuiInputBase-root": {
                          height: "41px",
                        },
                      },
                    },
                  }}></DatePicker>
              </LocalizationProvider>
            </FormControl>
          </Box>
          <LoadingButton
            variant='contained'
            color='primary'
            onClick={() => fetchOrders(since, until)}
            loading={loading}
            sx={{ mt: 2.7, height: "40px", width: "10%" }}>
            <SearchIcon />
          </LoadingButton>
          <Button
            onClick={handleRefresh}
            variant='contained'
            color='primary'
            sx={{ mt: 2.7, height: "40px", width: "10%" }}>
            <CachedIcon />
          </Button>
        </Box>
        <Box sx={{ display: "flex", gap: 1, mt: 2.7 }}>
          {legends.map((item, index) => (
            <CustomLegend
              key={item.label}
              onClick={() => handleLegendClick(index)}
              hidden={item.hidden}
              bgcolor={item.backgroundColor}
              fontcolor={item.fontColor}>
              {item.label}
            </CustomLegend>
          ))}
        </Box>
      </Box>
      {datesError && (
        <Typography color='error' sx={{ fontSize: "12px", mb: 1 }}>
          {datesError}
        </Typography>
      )}
      <ChartContainer height='220px'>
        <Box sx={{ height: "190px" }}>
          {loading && (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <CircularProgress />
            </Box>
          )}
          {!loading && !error && (
            <Bar options={options} data={chartData} ref={chartRef} onClick={handleElementClick} />
          )}
          {!loading && error && (
            <Box>
              <CustomAlert
                severity='error'
                text={"Error al obtener datos sobre las órdenes"}
                onClose={() => setError(false)}
              />
            </Box>
          )}
        </Box>
      </ChartContainer>
      {modalData && modalTitle ? (
        <DetailModal
          open={openModal}
          handleClose={handleCloseModal}
          data={modalData}
          title={modalTitle}
        />
      ) : null}
    </>
  );
};

export default OrdersByType;

type Legend = { bgcolor: string; fontcolor: string; hidden: boolean };

const CustomLegend = styled(Box)<Legend>(({ bgcolor, fontcolor, hidden }) => ({
  color: !hidden ? fontcolor : "#666666",
  backgroundColor: !hidden ? bgcolor : "#CCCCCC",
  borderRadius: "50px",
  padding: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  fontSize: "14px",
}));

const CustomTextField = styled(TextField)`
  border-radius: 12px;
  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-radius: 12px;
  }
  .MuiInputBase-root {
    height: 41px;
  }
`;
