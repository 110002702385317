import {
  Autocomplete,
  Button,
  CachedIcon,
  FormHelperText,
  Grid,
  LoadingButton,
  Typography,
} from "@enerbit/base";
import { Controller, SubmitHandler, UseFormReturn } from "react-hook-form";
import CustomTextField from "../CustomTextField/CustomTextField";
import { CalendarFormData } from "../../models/orders";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

interface Props {
  form: UseFormReturn<CalendarFormData, any, undefined>;
  onSubmit: SubmitHandler<CalendarFormData>;
  onReset: () => void;
}

const CalendarSearch = ({ form, onSubmit, onReset }: Props) => {
  const { statesList, loading } = useSelector((state: RootState) => state.calendar);

  const {
    handleSubmit,
    formState: { errors: formErrors },
    control,
    setValue,
  } = form;

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ marginBottom: "24px" }}>
      <Grid container columnSpacing={3}>
        <Grid item md={4}>
          <Typography>Región</Typography>
          <Controller
            name='state'
            control={control}
            render={({ field }) => (
              <Autocomplete
                {...field}
                multiple
                id='options'
                options={statesList}
                renderInput={(params) => <CustomTextField {...params} />}
                onChange={(_, value) => setValue("state", value)}
              />
            )}
          />
        </Grid>
        <Grid item md={4}>
          <Typography>Correo electrónico</Typography>
          <Controller
            name='email'
            control={control}
            render={({ field }) => (
              <CustomTextField
                fullWidth
                {...field}
                error={!!formErrors.email && !!formErrors.email.message}
                helperText={formErrors.email?.message && formErrors.email.message}
              />
            )}
          />
        </Grid>
        <Grid item md={3}>
          <LoadingButton
            fullWidth
            variant='contained'
            color='primary'
            type='submit'
            loading={loading}
            sx={{ mt: 2.7, height: "52px" }}>
            Buscar
          </LoadingButton>
        </Grid>
        <Grid item md={1}>
          <Button
            fullWidth
            variant='contained'
            color='primary'
            sx={{ mt: 2.7, height: "52px" }}
            onClick={onReset}>
            <CachedIcon />
          </Button>
        </Grid>
      </Grid>
      {formErrors && formErrors["at-least-one" as keyof CalendarFormData]?.message && (
        <FormHelperText error>
          {formErrors["at-least-one" as keyof CalendarFormData]?.message}
        </FormHelperText>
      )}
    </form>
  );
};

export default CalendarSearch;

{
  /* <Controller
            name='state'
            control={control}
            render={({ field }) => (
              <Autocomplete
                {...field}
                multiple
                getOptionLabel={(option) => option}
                defaultValue={[""]}
                isOptionEqualToValue={(option, value) => option === value}
                options={listStates}
                renderInput={(params) => <CustomTextField {...params} />}
                onChange={(_, data) => setValue("state", data.join(","))}
              />
            )}
          /> */
}
