import {
  Box,
  Button,
  CircularProgress,
  CloseIcon,
  CustomAlert,
  Grid,
  Modal,
  Typography,
} from "@enerbit/base";
import { OrdersByMeasurementType, OrdersByMeasurementTypeDetail } from "../../models/orders";
import { useEffect, useState } from "react";
import { getOrderDetailBulk } from "../../services";
import OrderAccordion from "./OrderAccordion";

interface Props {
  open: boolean;
  handleClose: () => void;
  data: { orders: OrdersByMeasurementType; selectedMeasure: string };
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "700px",
  bgcolor: "background.paper",
  p: 3,
  borderRadius: "10px",
  maxHeight: "900px",
};

const DetailModal = ({ open, handleClose, data }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [orders, setOrders] = useState<OrdersByMeasurementTypeDetail[]>([]);

  const fetchDetail = async () => {
    setError(false);
    setLoading(true);
    try {
      const ids = Array.from(new Set(data.orders.order_ids));
      const tempOrders = await getOrderDetailBulk(ids, data.selectedMeasure);

      setOrders(tempOrders);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const onClose = () => {
    setLoading(false);
    setError(false);
    setOrders([]);
    handleClose();
  };

  useEffect(() => {
    if (open) {
      fetchDetail();
    }
  }, [open]);

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: 1 }}>
          <Typography color={"primary"} sx={{ fontSize: "21px", fontWeight: 700 }}>
            Detalle de las órdenes ejecutadas
          </Typography>
          <Button onClick={handleClose} variant='outlined' className='back-btn'>
            <CloseIcon />
          </Button>
        </Box>
        {loading && (
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        )}
        {!loading && error && (
          <Box my={2}>
            <CustomAlert severity='error' text='Error consultando órdenes' onClose={() => {}} />
          </Box>
        )}
        {!loading && !error && (
          <Box sx={{ overflowY: "auto", maxHeight: "500px" }}>
            <Box
              sx={{
                height: "49px",
                padding: "8px 16px 8px 16px",
                backgroundColor: "#EEEBF4",
                width: "100%",
                borderRadius: "12px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <Grid container>
                <Grid item md={5.5}>
                  Orden de servicio
                </Grid>
                <Grid item md={3}>
                  Tiempo
                </Grid>
                <Grid item md={3}>
                  <Typography sx={{ textAlign: "right" }}>Acta</Typography>
                </Grid>
              </Grid>
            </Box>
            {orders.length > 0 && (
              <>
                {orders.map((order, index) => (
                  <OrderAccordion key={index} index={index} order={order} />
                ))}
              </>
            )}
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default DetailModal;
