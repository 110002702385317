import {
  Box,
  Button,
  CloseIcon,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@enerbit/base";
import { ORDER_NAMES } from "../../constants/orders";
import { OrderCodes } from "../../models/orders";
import { sortObjectByValue } from "../../helpers";

interface Props {
  open: boolean;
  handleClose: () => void;
  data: Record<string, any>;
  title: string;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "700px",
  bgcolor: "background.paper",
  p: 3,
  borderRadius: "10px",
};

const DetailModal = ({ open, handleClose, data, title }: Props) => {
  const sortedData = sortObjectByValue(data);
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: 1 }}>
          <Typography color={"primary"} sx={{ fontSize: "21px", fontWeight: 700 }}>
            {title}
          </Typography>
          <Button onClick={handleClose} variant='outlined' className='back-btn'>
            <CloseIcon />
          </Button>
        </Box>
        <Box>
          <TableContainer component={Box} sx={{ mt: 2 }}>
            <Table sx={{ minWidth: 650, borderCollapse: "separate", borderSpacing: "0 4px" }}>
              <TableHead>
                <TableRow
                  sx={{
                    ".MuiTableCell-root": {
                      backgroundColor: "#EEEBF4 !important",
                      padding: "8px 16px !important",
                    },
                  }}>
                  <TableCell>
                    <Typography sx={{ fontWeight: 700 }}>Orden de servicio</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography sx={{ fontWeight: 700 }}>Cantidad</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(sortedData).map((key, index) => (
                  <TableRow
                    key={key}
                    sx={{
                      backgroundColor: "#F2F4F7",
                      ".MuiTableCell-root": { borderBottom: "none !important" },
                    }}>
                    <TableCell>{ORDER_NAMES[key as OrderCodes]}</TableCell>
                    <TableCell>{sortedData[key]}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Modal>
  );
};

export default DetailModal;
