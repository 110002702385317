import { Box, Button, ButtonGroup, Menu, SxProps, Typography, styled } from "@enerbit/base";
import moment from "moment";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { ToolbarProps, View } from "react-big-calendar";
import { Calendar } from "react-date-range";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useState } from "react";

moment.locale("es");

const VIEW_OPTIONS: { label: string; view: View }[] = [
  { label: "Día", view: "day" },
  { label: "Mes", view: "month" },
];

const CustomCalendarToolbar = ({ date, view, onView, onNavigate }: ToolbarProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [newDate, setNewDate] = useState<Date>(new Date());

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const navToPrevious = () => {
    onNavigate("PREV");
  };

  const navToNext = () => {
    onNavigate("NEXT");
  };

  const todayButtonsStyles: SxProps = {
    borderRadius: "20px !important",
    height: "40px",
    border: "2px solid #F2F4F7 !important",
    boxShadow: "0px 2px 5px 0px rgba(38, 51, 77, 0.03)",
    color: "#667085",
  };

  const navigationButtonsStyles: SxProps = {
    border: "2px solid #F2F4F7 !important",
    borderRadius: "50% !important",
    height: "40px !important",
    width: "40px !important",
    minWidth: "unset !important",
    boxShadow: "0px 2px 5px 0px rgba(38, 51, 77, 0.03)",
    color: "#667085",
  };

  const viewButtonsStyles: SxProps = {
    height: "40px !important",
    border: "2px solid #F2F4F7 !important",
  };

  const handleSelect = (date: Date) => {
    setNewDate(date);
    onNavigate("DATE", date);
    onView("day");
    handleCloseMenu();
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", pb: 3 }}>
      <Box sx={{ display: "flex", gap: 1 }}>
        <Button variant='outlined' sx={todayButtonsStyles} onClick={() => onNavigate("TODAY")}>
          Hoy
        </Button>
        <Button variant='outlined' sx={todayButtonsStyles} onClick={handleOpenMenu}>
          <CalendarMonthIcon />
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
          MenuListProps={{ "aria-labelledby": "basic-button" }}>
          <Calendar date={newDate} onChange={handleSelect} />
        </Menu>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: 2 }}>
        <Button sx={navigationButtonsStyles} onClick={() => navToPrevious()}>
          <KeyboardArrowLeftIcon />
        </Button>
        <Typography sx={{ fontSize: "12px", fontWeight: 700, color: "#667085" }}>
          {moment(date).format("MMMM D, YYYY")}
        </Typography>
        <Button sx={navigationButtonsStyles} onClick={() => navToNext()}>
          <KeyboardArrowRightIcon />
        </Button>
      </Box>
      <ButtonGroup variant='outlined'>
        {VIEW_OPTIONS.map((item, index) => (
          <Button
            key={index + 1}
            sx={{
              ...viewButtonsStyles,
              color: item.view === view ? "#667085" : "#E4E7EC",
              borderRadius:
                index === 0
                  ? "30px 0px 0px 30px !important"
                  : index === VIEW_OPTIONS.length - 1
                  ? "0px 30px 30px 0px !important"
                  : "unset !important",
            }}
            onClick={() => onView(item.view)}>
            {item.label}
          </Button>
        ))}
      </ButtonGroup>
    </Box>
  );
};

export default CustomCalendarToolbar;
