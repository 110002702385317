import { api } from "@enerbit/base";
import {
  CertificatesResponse,
  GetOrdersCalendarParams,
  GetOrdersResponse,
  Lead,
  MeasurementPoint,
  OperatorData,
  OperatorDetail,
  OrderEntity,
  OrderEntityCode,
  OrderHistorical,
  Orders,
  OrdersByGeo,
  OrdersByMeasurementType,
  OrdersByMeasurementTypeDetail,
  OrdersByStatus,
  OrdersByType,
  SearchOrdersResponse,
  SmartBitAssignee,
} from "../models/orders";
import { CABINET_ORDERS } from "../constants/orders";
import axios from "axios";

export const geoPoliticsApi = axios.create({
  baseURL: `https://back.enerbit.app/geopolitics`,
});

// export const getStatesByCounntryId = async () => {
//   try {
//     const { data } = await geoPoliticsApi.get<GeopoliticsResponse>(`/states/?country_code=co`);
//     const listState = data.items.map((state) => {
//       return state.name;
//     });
//     return listState;
//   } catch (error: any) {
//     return [];
//   }
// };

export const getOrdersByStatus = async (since: string, until: string) => {
  const params: Record<string, any> = { since, until };
  const res = await api.get<GetOrdersResponse<OrdersByStatus[]>>(
    "/service-orders/orders/by-status",
    { params }
  );

  return res.data.items;
};

export const getOrdersByMeasurementType = async (since: string, until: string) => {
  const params: Record<string, any> = { since, until };
  const res = await api.get<GetOrdersResponse<OrdersByMeasurementType[]>>(
    "/service-orders/orders/by-measurement-type",
    { params }
  );

  return res.data.items;
};

export const getOrdersByType = async (since: string, until: string) => {
  const params: Record<string, any> = { since, until };
  const res = await api.get<GetOrdersResponse<OrdersByType[]>>("/service-orders/orders/by-type", {
    params,
  });

  return res.data.items;
};

export const getOperatorData = async (email: string) => {
  const params: Record<string, any> = { username: email };

  const res = await api.get<{ items: OperatorData[] }>("/ops-users/operators/", { params });

  return res.data.items[0];
};

export const getCalendarOrders = async ({
  state,
  operator_id,
  since,
  until,
}: GetOrdersCalendarParams) => {
  let nextPage: number | null = 0;
  const tempOrders: Orders[] = [];

  // do {
  //   const params: Record<string, any> = {
  //     planned_date_begin: since,
  //     planned_date_end: until,
  //     size: 15,
  //     page: nextPage,
  //   };

  //   if (operatorId) params["operator_id"] = operatorId;
  //   if (state) params["state"] = state;

  //   const res = await api.get<SearchOrdersResponse>("/service-orders/orders", { params });
  //   res.data.items.forEach((order) => tempOrders.push(order));

  //   nextPage = res.data.next_page;
  // } while (nextPage !== null);

  return tempOrders;
};

export const getOperatorDetail = async (id: string) => {
  const res = await api.get<OperatorDetail>(`/ops-users/operators/${id}/`);

  return res.data;
};

export const authPicture = async (url: string) => {
  const res = await api.get<{ url: string }>(
    `/service-orders-certificates/authenticate-file?url_path=${encodeURIComponent(url)}`
  );
  return res.data.url;
};

export const getOrderHistorical = async (orderId: string) => {
  const res = await api.get<{ items: OrderHistorical[] }>(
    `/service-orders/order-historical?order_id=${orderId}&event=created&page=0&size=50`
  );

  return res.data.items.filter((item) => item.event === "created" && item.event_on === "orders");
};

export const getOrderClientData = async (orderEntities: OrderEntity[]) => {
  // if (orderEntities.length === 0) {
  //   return {
  //     address: "",
  //     id: "",
  //     status: "",
  //   };
  // }
  const requests = orderEntities.map(async (entity) => {
    if (entity.entity.code === OrderEntityCode.LD) {
      return await getLead(entity.assignee_id, entity.status);
    } else if (entity.entity.code === OrderEntityCode.SMB) {
      return await getSmartbitAssignee(entity.assignee_id, entity.status);
    }

    return await getMeasurementPoint(entity.assignee_id, entity.status);
  });

  const data = await Promise.all(requests);

  return data;
};

export const getLead = async (id: string, status: string) => {
  const res = await api.get<Lead>(`/assignees/leads/${id}`);

  const data = {
    fullName: res.data.display_name,
    address: res.data.address,
    email: res.data.email_from,
    status,
    id,
  };

  return data;
};

export const getSmartbitAssignee = async (id: string, status: string) => {
  const res = await api.get<{ items: SmartBitAssignee[] }>(`/assignees/smartbits?device_id=${id}`);
  const data = { address: res.data.items[0].location.address, id, status };

  return data;
};

export const getMeasurementPoint = async (id: string, status: string) => {
  const res = await api.get<MeasurementPoint>(`/assignees/measurement-point/${id}`);

  const data = { address: res.data.location.address, id, status };
  return data;
};

export const getOrdersByGeo = async (since: string, until: string) => {
  const res = await api.get<{ items: OrdersByGeo[] }>(
    `/service-orders/orders/by-geo?since=${encodeURIComponent(since)}&until=${encodeURIComponent(
      until
    )}`
  );

  return res.data.items;
};

export const getOrderDetailBulk = async (ids: string[], measurementType: string) => {
  const data: OrdersByMeasurementTypeDetail[] = [];

  const requests = ids.map(async (id) => {
    let orderData: OrdersByMeasurementTypeDetail;
    const order = await api.get<Orders>(`/service-orders/orders/${id}`);
    const certificates = await api.get<CertificatesResponse>(
      `/service-orders-certificates/certificates?order_id=${id}&page=0&size=50`
    );

    const tempCerificates: {
      url: string;
      measurementType: string;
      id: string;
      meterSerial: string;
    }[] = certificates.data.items
      .filter(
        (certificate) =>
          certificate.form_data.main_meter_installed &&
          certificate.form_data.main_meter_installed.measurement_type.toLowerCase() ===
            measurementType.toLowerCase()
      )
      .map((certificate) => {
        return {
          url: certificate.document_url,
          measurementType: certificate.form_data.main_meter_installed.measurement_type,
          id: certificate.id,
          meterSerial: certificate.form_data.main_meter_installed.meter_serial.value,
        };
      });

    orderData = {
      name: order.data.order_type.name,
      startDate: order.data.order_done.form_data.metric.started_at,
      endDate: order.data.order_done.form_data.metric.ended_at,
      certificates: tempCerificates,
      isCabinet: CABINET_ORDERS.includes(order.data.order_type.code),
    };

    data.push(orderData);
  });

  await Promise.all(requests);

  return data
    .filter((item) => item.certificates.length > 0)
    .sort((a, b) => (a.isCabinet === b.isCabinet ? 0 : a.isCabinet ? -1 : 1));
};

export const authCertificate = async (url: string) => {
  const res = await api.get<{ url: string }>(
    `service-orders-certificates/authenticate-file?url_path=${encodeURIComponent(url)}`
  );

  return res.data.url;
};
