import { useEffect, useRef, useState } from "react";
import {
  Alert,
  Box,
  Button,
  CachedIcon,
  CircularProgress,
  Grid,
  SxProps,
  Typography,
  enerbitColors,
  styled,
} from "@enerbit/base";
import ChartContainer from "../ChartContainer/ChartContainer";
import { formatAverageHoursDatasets } from "../../helpers";
import moment from "moment";
import { DATE_TIME_FORMAT } from "../../constants/orders";
import { getOrdersByMeasurementType } from "../../services";
import { Chart, getElementAtEvent } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  ChartData,
  PointElement,
  LineElement,
  LineController,
  BarController,
  Filler,
  LogarithmicScale,
  ChartOptions,
} from "chart.js";
import ChartDataLabel from "chartjs-plugin-datalabels";
import Filter from "./Filter";
import {
  AverageHoursViewType,
  ChartLegend,
  OrdersByMeasurementType as OrdersByMeasureModel,
} from "../../models/orders";
import DetailModal from "./DetailModal";
import useMediaQuery from "../../hooks/useMediaQuery";
import { MOBILE_BREAKPOINT } from "../../constants/common";

ChartJS.register(
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  BarElement,
  Title,
  Tooltip,
  PointElement,
  LineElement,
  LineController,
  BarController,
  Filler,
  ChartDataLabel
);

const refreshButtonStyles: SxProps = {
  minHeight: "unset !important",
  width: "20px",
  height: "20px",
  borderRadius: "100% !important",
  minWidth: "unset !important",
  padding: "16px !important",
};

const DEFAULT_SINCE = moment().subtract(15, "days").startOf("day").format(DATE_TIME_FORMAT);
const DEFAULT_UNTIL = moment().endOf("day").format(DATE_TIME_FORMAT);

interface CustomLegend extends ChartLegend {
  index: number;
}

const OrdersByMeasurementType = () => {
  const [legend, setLegend] = useState<CustomLegend[]>([
    { label: "Directa", backgroundColor: "#3B82F6", hidden: false, index: 3 },
    { label: "Semidirecta", backgroundColor: "#27AE60", hidden: false, index: 4 },
    { label: "Indirecta", backgroundColor: "#F29139", hidden: false, index: 5 },
  ]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [isEmpty, setIsEmpty] = useState<boolean>(false);
  const [chartData, setChartData] = useState<ChartData<any, number[], string>>({
    datasets: [],
    labels: [],
  });
  const [viewType, setViewType] = useState<AverageHoursViewType>(AverageHoursViewType.DAY);
  const [since, setSince] = useState<string>("");
  const [until, setUntil] = useState<string>("");
  const [title, setTitle] = useState<string>(
    `Minutos promedio ${moment().subtract(15, "days").format("DD MMMM")} - ${moment().format(
      "DD MMMM"
    )}`
  );
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [totalData, setTotalData] = useState<OrdersByMeasureModel[] | undefined>(undefined);
  const [modalData, setModalData] = useState<
    { orders: OrdersByMeasureModel; selectedMeasure: string } | undefined
  >(undefined);
  const [options, setOptions] = useState<ChartOptions<"bar">>({});

  const isTablet = useMediaQuery(MOBILE_BREAKPOINT);

  const chartRef = useRef<any>(null);

  const footer = (tooltipItems: any, data: OrdersByMeasureModel[], view: AverageHoursViewType) => {
    let sum = 0;
    const label = tooltipItems[0].label;
    const datasetIndex = tooltipItems[0].datasetIndex;
    const datasetLabel = tooltipItems[0].dataset.label;

    if (datasetIndex > 2) {
      const element = data.find(
        (item) =>
          moment(item.formatted_date)
            .utcOffset(0)
            .format(view === AverageHoursViewType.DAY ? "DD" : "MMMM") === label
      );

      if (!element) return "";
      if (datasetLabel === "Directa") {
        sum = element.total_orders_directa;
      } else if (datasetLabel === "Semidirecta") {
        sum = element.total_orders_semidirecta;
      } else {
        sum = element.total_orders_indirecta;
      }
    } else {
      if (datasetLabel === "Horas promedio directa") {
        sum = data.reduce((acc, entry) => acc + entry.total_orders_directa, 0);
      } else if (datasetLabel === "Horas promedio semidirecta") {
        sum = data.reduce((acc, entry) => acc + entry.total_orders_semidirecta, 0);
      } else {
        sum = data.reduce((acc, entry) => acc + entry.total_orders_indirecta, 0);
      }
    }
    return "N° de instalaciones: " + sum;
  };

  const hideAverageCharts = (index: number, value: boolean, chart: any) => {
    chart.getDatasetMeta(index).hidden = value;
    chart.update();
  };

  const handleLegendClick = (datasetIndex: number) => {
    const chart = chartRef.current;
    chart.getDatasetMeta(datasetIndex).hidden =
      chart.getDatasetMeta(datasetIndex).hidden === null
        ? true
        : !chart.getDatasetMeta(datasetIndex).hidden;

    if (chart.getDatasetMeta(4).hidden && chart.getDatasetMeta(5).hidden) {
      hideAverageCharts(0, false, chart);
      hideAverageCharts(1, true, chart);
      hideAverageCharts(2, true, chart);
    } else if (chart.getDatasetMeta(3).hidden && chart.getDatasetMeta(4).hidden) {
      hideAverageCharts(0, true, chart);
      hideAverageCharts(1, true, chart);
      hideAverageCharts(2, false, chart);
    } else if (chart.getDatasetMeta(3).hidden && chart.getDatasetMeta(5).hidden) {
      hideAverageCharts(0, true, chart);
      hideAverageCharts(1, false, chart);
      hideAverageCharts(2, true, chart);
    } else {
      hideAverageCharts(0, true, chart);
      hideAverageCharts(1, true, chart);
      hideAverageCharts(2, true, chart);
    }

    if (
      chart.getDatasetMeta(3).hidden &&
      chart.getDatasetMeta(4).hidden &&
      chart.getDatasetMeta(5).hidden
    ) {
      hideAverageCharts(0, true, chart);
      hideAverageCharts(1, true, chart);
      hideAverageCharts(2, true, chart);
    }

    setLegend((prev) => {
      const nD = [...prev];
      nD[datasetIndex - 3].hidden = chart.getDatasetMeta(datasetIndex).hidden;
      return nD;
    });

    chart.update();
  };

  const getTitle = (refresh?: boolean) => {
    if (refresh) {
      return `Minutos promedio ${moment()
        .subtract(15, "days")
        .format("DD MMMM")} - ${moment().format("DD MMMM")}`;
    } else if (viewType === AverageHoursViewType.DAY) {
      return `Minutos promedio ${moment(since).format("DD MMMM")} - ${moment(until).format(
        "DD MMMM"
      )}`;
    } else {
      return `Minutos promedio ${moment(since).format("MMMM")} - ${moment(until).format("MMMM")}`;
    }
    return "";
  };

  const fetchOrderHours = async (tSince: string, tUntil: string, refresh?: boolean) => {
    setLegend((prev) => {
      const nD = prev.map((legend) => ({ ...legend, hidden: false }));
      return nD;
    });

    setIsEmpty(false);
    setError(false);
    setLoading(true);

    try {
      const tOrders = await getOrdersByMeasurementType(tSince, tUntil);

      setIsEmpty(tOrders.length === 0);

      const { datasets, labels, data } = formatAverageHoursDatasets(
        tOrders,
        !refresh ? viewType : AverageHoursViewType.DAY
      );

      setTotalData(data);

      setOptions({
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
          },
          datalabels: {
            display: false,
            rotation: -90,
            color: "white",
            anchor: "center",
            align: "center",
            formatter: (value: any, context) => {
              return !isNaN(value) && value !== 0 && context.dataset.type !== "line" ? value : null;
            },
          },
          tooltip: {
            callbacks: {
              title: (tooltipItems: any) =>
                footer(tooltipItems, data, !refresh ? viewType : AverageHoursViewType.DAY),
              label: (tooltipItems: any) => {
                return tooltipItems.datasetIndex > 2
                  ? `Minutos promedio ${tooltipItems.dataset.label.toLowerCase()}: ${
                      tooltipItems.formattedValue
                    }`
                  : `${tooltipItems.dataset.label}: ${tooltipItems.formattedValue}`;
              },
            },
          },
        },
        scales: {
          y: {
            title: {
              display: true,
              text: "Minutos promedio",
              font: { family: "Helvetica Neue LT Std" },
            },
            type: "logarithmic",
          },
          x: {
            title: { display: true, text: "Fecha", font: { family: "Helvetica Neue LT Std" } },
          },
        },
      });

      setChartData({ datasets, labels });
      setTitle(getTitle(refresh));
    } catch (error) {
      setError(true);
      setIsEmpty(false);
    } finally {
      setLoading(false);
    }
  };

  const onRefresh = () => {
    setSince("");
    setUntil("");
    setViewType(AverageHoursViewType.DAY);
    fetchOrderHours(DEFAULT_SINCE, DEFAULT_UNTIL, true);
  };

  const handleElementClick = (event: React.MouseEvent<HTMLCanvasElement>) => {
    const selectedItem = getElementAtEvent(chartRef.current, event);

    if (selectedItem.length === 0 || !totalData) return;

    setOpenModal(true);
    const metaData = chartRef.current.getDatasetMeta(selectedItem[0].datasetIndex);
    const index = selectedItem[0].index;
    setModalData({ orders: totalData[index], selectedMeasure: metaData.label });
  };

  useEffect(() => {
    fetchOrderHours(DEFAULT_SINCE, DEFAULT_UNTIL, true);
  }, []);

  return (
    <>
      <Box
        height='250px'
        sx={{
          display: "flex",
          flexDirection: "column",
          // justifyContent: "space-evenly",
          gap: 2,
          backgroundColor: enerbitColors.neutral[100],
          borderRadius: "8px",
          padding: "24px",
          height: "100%",
        }}>
        <Box
          sx={{
            display: isTablet ? "block" : "flex",
            alignItems: "center",
            flexDirection: isTablet ? "column" : "row",
            justifyContent: "space-between",
            gap: 1,
            mb: 1,
          }}>
          <Box sx={{ display: "flex", gap: 1 }}>
            <Typography sx={{ fontWeight: 600 }}>{title}</Typography>
            <Divisor />
            <Box sx={{ display: "flex", gap: 3 }}>
              {legend.map((item, index) => (
                <Box
                  onClick={() => {
                    handleLegendClick(item.index);
                  }}
                  key={index + 1}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    cursor: "pointer",
                  }}>
                  <Dot bgcolor={item.backgroundColor} />
                  <Typography
                    sx={{
                      fontSize: "12px",
                      textDecoration: item.hidden ? "line-through" : undefined,
                    }}>
                    {item.label}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              mt: isTablet ? 1 : 0,
            }}>
            <Filter
              viewType={viewType}
              setViewType={setViewType}
              fetchOrderHours={fetchOrderHours}
              setSince={setSince}
              setUntil={setUntil}
              since={since}
              until={until}
            />
            <Button variant='contained' sx={refreshButtonStyles} onClick={onRefresh}>
              <CachedIcon />
            </Button>
          </Box>
        </Box>
        {error && (
          <Box>
            <Alert severity='error'>Error consultando los datos.</Alert>
          </Box>
        )}
        {!isEmpty && !loading && !error && (
          <Box sx={{ height: "190px" }}>
            <Chart
              type='bar'
              options={options}
              data={chartData}
              ref={chartRef}
              onClick={handleElementClick}
            />
          </Box>
        )}
        {isEmpty && !error && (
          <Box>
            <Alert severity='info'>No se encontraron datos para mostrar.</Alert>
          </Box>
        )}
        {loading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
            }}>
            <CircularProgress />
          </Box>
        )}
      </Box>
      {modalData && (
        <DetailModal open={openModal} handleClose={() => setOpenModal(false)} data={modalData} />
      )}
    </>
  );
};

export default OrdersByMeasurementType;

type DotProps = { bgcolor: string };

const Divisor = styled("div")(() => ({
  border: "1px solid #1C1C1C33",
}));

const Dot = styled("div")<DotProps>(({ bgcolor }) => ({
  backgroundColor: bgcolor,
  width: "7px",
  height: "7px",
  borderRadius: "50%",
}));
