import { styled } from "@enerbit/base";
import Bombi from "../../assets/Bombi-AE.gif";
import ModalMask from "../ModalMask/ModalMask";

const CalendarLoader = () => {
  return (
    <ModalMask>
      <BombiContainer>
        <img src={Bombi} alt='bombi' width='250px' />
      </BombiContainer>
    </ModalMask>
  );
};

export default CalendarLoader;

const BombiContainer = styled("label")`
  position: absolute;
  align-items: center;
  justify-content: center;
  display: flex;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
`;
