import { Event } from "react-big-calendar";

export enum OrderStatus {
  DONE = "done",
  PENDING = "pending",
  CANCEL = "cancel",
  PARTIAL_DONE = "partial_done",
  PENDING_ASSIGNMENT = "pending_assignment",
}

export interface GetOrdersResponse<T> {
  items: T;
}
export interface OrdersByStatus {
  formatted_date: string;
  total_orders: number;
  total_done_orders: number;
  total_pending_orders: number;
  total_cancelled_orders: number;
}

export interface OrdersByMeasurementType {
  formatted_date: string;
  total_orders: number;
  total_time_directa: number;
  total_time_indirecta: number;
  total_time_semidirecta: number;
  total_orders_directa: number;
  total_orders_indirecta: number;
  total_orders_semidirecta: number;
  order_ids: string[];
}

export interface OrdersByType {
  formatted_date: string;
  order_type: string;
  total_orders: number;
  order_code: OrderCodes;
}

export interface OperatorData {
  id: string;
}

export interface GetOrdersCalendarParams {
  [key: string]: string;
}

export enum OrderCodes {
  CM = "CM",
  CC = "CC",
  NT = "NT",
  RSE = "RSE",
  RDCE = "RDCE",
  RP = "RP",
  SSSE = "SSSE",
  SSE = "SSE",
  RPSM = "RPSM",
  CCEG = "CCEG",
  NSG = "NSG",
  AIV = "AIV",
  MP = "MP",
  CSG = "CSG",
  NSCN_RPE = "NSCN-RPE",
  NCMP = "NCMP",
  IPP = "IPP",
  CCE = "CCE",
  ISBG = "ISBG",
  CS = "CS",
  II = "II",
  IG = "IG",
  IPCCE = "IPCCE",
  NS = "NS",
  NSDG = "NSDG",
  NSDI = "NSDI",
  AEMERG = "AEMERG",
  DSM = "DSM",
  ADCSM = "ADCSM",
  NORM_CGM_THIRD = "NORM_CGM_THIRD",
  REMOV_CE_NO_INST = "REMOV_CE_NO_INST",
}

export enum OrderGroups {
  INSTALLATION = "INSTALLATION",
  INSPECTION = "INSPECTION",
  NORMALIZATION = "NORMALIZATION",
  SUSPENSION_RECONNECTION = "SUSPENSION_RECONNECTION",
  CANCELATION = "CANCELATION",
}

export interface ChartDatasets {
  label: string;
  dataKey: OrderGroups;
  backgroundColor: string;
}

export interface CalendarFormData {
  email: string;
  state: string[];
}

export interface SearchOrdersResponse {
  items: Orders[];
  page: number;
  size: 50;
  total: number;
  pages: number;
  next_page: number | null;
  previous_page: number | null;
}

export interface Orders {
  id: string;
  status: OrderStatus;
  priority: string;
  state: string;
  city: string;
  form_data: { description: string };
  order_type: OrderType;
  order_operators: OrderOperators[] | null;
  work_group_id: string | null;
  order_done: OrderDone;
  order_cancel: OrderCancel;
  order_entities: OrderEntity[];
  planned_date_begin: string;
  planned_date_end: string;
}

export enum OrderEntityCode {
  SMB = "SMB",
  LD = "LD",
  MP = "MP",
}

export interface OrderEntity {
  id: string;
  assignee_id: string;
  entity: Entity;
  status: string;
}

export interface Entity {
  id: string;
  name: string;
  code: OrderEntityCode;
  created_at: string;
  updated_at: string | null;
  pii: Pii;
  status: OrderStatus;
}

export interface OrderType {
  id: string;
  name: string;
  code: OrderCodes;
  is_active: boolean;
  created_at: string;
  updated_at: string | null;
}

export interface OrderOperators {
  id: string;
  operator_id: string;
  created_at: string;
  updated_at: string | null;
  pii: Pii;
}

export interface Pii {
  full_name: string;
  legal_id_type: string;
  legal_id_code: string;
  phone: string;
  rol: string;
  email: string;
  company: Company;
}

export interface Company {
  name: string;
  legal_id_code: string;
  address: string;
  phone: string;
  email: string;
  id: string;
  created_at: string;
  updated_at: string | null;
}

export interface OrderDone {
  id: string;
  done_by: string;
  form_data: {
    geolocalization: Geolocalization;
    metric: OrderDoneMetric;
    inconsistencies?: Inconsistencies[];
  };
  created_at: string;
  updated_at: string | null;
}

export enum InconsistencyTypes {
  LATE_START = "late_start",
  EXCEEDED_TIME = "exceeded_time",
  DIFFERENT_LOCATION = "different_location",
}

export interface Inconsistencies {
  inconsistency_type: InconsistencyTypes;
  reason: string;
  different_location: { initial: Geolocalization; final: Geolocalization } | null;
  scheduled_time_difference: { scheduled_time: string; actual_time: string } | null;
  time_exceeded_average: { average: number; actual: number } | null;
}

export interface Geolocalization {
  altitude: number;
  latitude: number;
  longitude: number;
}

export interface OrderDoneMetric {
  ended_at: string;
  started_at: string;
}

export interface OrderCancel {
  id: string;
  cancel_by: string;
  observations: string;
  picture_url: string;
  created_at: string;
  updated_at: string;
  form_data: { geolocalization: Geolocalization };
  cancellation_reason: CancellationReason;
}

export interface CancellationReason {
  id: string;
  name: string;
  code: string;
  created_at: string;
  updated_at: string;
}

export interface CustomCalendarEvents extends Event {
  order: Orders;
}

export interface OperatorDetail {
  pii: Pii;
  id: string;
  username: string;
}

export enum AverageHoursViewType {
  DAY = "DAY",
  MONTH = "MONTH",
}

export interface ChartLegend {
  label: string;
  backgroundColor: string;
  hidden: boolean;
}

export interface OrderHistorical {
  responsible: { email: string; names: string; operator_id: string };
  id: string;
  event: string;
  event_on: string;
  created_at: string;
}

export interface Lead {
  id: string;
  name: string;
  last_name: string;
  city: string;
  department: string;
  email_from: string;
  meter_serial_number: string;
  type_services: string;
  display_name: string;
  address: string;
  measurement_point_id: string;
}

export interface SmartBitAssignee {
  location: { address: string; country: string; state: string; city: string };
}

export interface MeasurementPoint {
  id: string;
  location: { address: string; country: string; state: string; city: string };
}

export interface OrderEventDetail {
  operator: OperatorDetail;
  picture?: string;
  responsible: { email: string; names: string; operator_id: string };
  entitie: { email?: string; address: string; fullName?: string; id: string; status: string }[];
}

export interface OrdersByGeo {
  id: string;
  status: string;
  state: string;
  order_type_code: string;
  order_type_name: string;
  planned_date_begin: string;
  planned_date_end: string;
  geolocalization: Geolocalization;
}

export interface CertificatesResponse {
  items: Certificates[];
}

export interface Certificates {
  id: string;
  document_url: string;
  order_id: string;
  form_data: CertificatesFormData;
}

export interface CertificatesFormData {
  main_meter_installed: { measurement_type: string; meter_serial: { value: string } };
}

export interface OrdersByMeasurementTypeDetail {
  name: string;
  isCabinet: boolean;
  startDate: string;
  endDate: string;
  certificates: { url: string; measurementType: string; id: string; meterSerial: string }[];
}
