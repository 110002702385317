import {
  AverageHoursViewType,
  ChartDatasets,
  OrderCodes,
  OrderEntityCode,
  OrderGroups,
  OrderStatus,
} from "../models/orders";

export const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss";

export const ORDER_STATUS: Record<OrderStatus, { label: string }> = {
  pending: { label: "Pendiente" },
  done: { label: "Completadas" },
  cancel: { label: "Canceladas" },
  partial_done: { label: "Completada parcialmente" },
  pending_assignment: { label: "Pendiente por asignar" },
};

export const CABINET_ORDERS: OrderCodes[] = [
  OrderCodes.CCEG,
  OrderCodes.NSG,
  OrderCodes.CSG,
  OrderCodes.ISBG,
  OrderCodes.IG,
  OrderCodes.NSDG,
];

export const ORDER_NAMES: Record<OrderCodes, string> = {
  [OrderCodes.CM]: "Cambio de medidor", // Normalization
  [OrderCodes.CC]: "Cancelación de cuenta", //Cancelation
  [OrderCodes.NT]: "Normalización del sistema de comunicación", //Normalization
  [OrderCodes.RSE]: "Reconexión del servicio de energía", //Suspension/Recconection
  [OrderCodes.RDCE]: "Revisión por desviación del consumo de energía", //Normalization
  [OrderCodes.RP]: "Revisión por PQR", //Normalization
  [OrderCodes.SSSE]: "Seguimiento de la suspensión del servicio de energía", //Suspension
  [OrderCodes.SSE]: "Suspensión del servicio de energía", // Suspension
  [OrderCodes.RPSM]: "Revisión o normalización preventiva del sistema de medida", //Normalization
  [OrderCodes.CCEG]: "Cambio de comercializador a enerBit - Gabinete", //Installation
  [OrderCodes.NSG]: "Nuevo servicio - Gabinete", //Installation
  [OrderCodes.AIV]: "Acompañamiento e inspección visual", //Installation
  [OrderCodes.MP]: "Modo planta",
  [OrderCodes.CSG]: "Cesión de servicio - Gabinete", //Installation
  [OrderCodes.NSCN_RPE]: "Normalización de servicio cliente notificado", //Normalization
  [OrderCodes.NCMP]: "Normalización por código de medida o preventiva", //Normalization
  [OrderCodes.IPP]: "Inspección previa - Proyecto", //Inspection
  [OrderCodes.CCE]: "Cambio de comercializador a enerBit - Individual", //Installation
  [OrderCodes.ISBG]: "Cambio de smartBit - Gabinete", //Installation
  [OrderCodes.CS]: "Cesión de servicio - Individual", //Installation
  [OrderCodes.II]: "Inspección de interventoria", //Inspection
  [OrderCodes.IG]: "Inspección previa - Gabinete", //Inspection
  [OrderCodes.IPCCE]: "Inspección previa - Individual", //Inspection
  [OrderCodes.NS]: "Nuevo servicio - Individual", //Installation
  [OrderCodes.NSDG]: "Nuevo servicio desenergizado - Gabinete", //Installation
  [OrderCodes.NSDI]: "Nuevo servicio desenergizado - Individual", //Installation
  [OrderCodes.AEMERG]: "Normalización atención de emergencias", //Normalization
  [OrderCodes.DSM]: "Atención cliente con afectación de servicio", //Normalization
  [OrderCodes.ADCSM]: "Verificación y/o actualización de datos del cliente", //Normalization
  [OrderCodes.NORM_CGM_THIRD]: "Normalización CGM de terceros", //Normalization
  [OrderCodes.REMOV_CE_NO_INST]: "Cancelación de cuenta sin instalación", //Cancelation
};

export const ORDERS_GROUPED_BY_TYPES: Record<OrderGroups, OrderCodes[]> = {
  [OrderGroups.INSTALLATION]: [
    OrderCodes.CCEG,
    OrderCodes.CCE,
    OrderCodes.CSG,
    OrderCodes.CS,
    OrderCodes.NSG,
    OrderCodes.NSDG,
    OrderCodes.NS,
    OrderCodes.NSDI,
    OrderCodes.MP,
  ],
  [OrderGroups.INSPECTION]: [
    OrderCodes.IPP,
    OrderCodes.IG,
    OrderCodes.IPCCE,
    OrderCodes.II,
    OrderCodes.AIV,
  ],
  [OrderGroups.NORMALIZATION]: [
    OrderCodes.AEMERG,
    OrderCodes.ISBG,
    OrderCodes.DSM,
    OrderCodes.NT,
    OrderCodes.NSCN_RPE,
    OrderCodes.NCMP,
    OrderCodes.RDCE,
    OrderCodes.RP,
    OrderCodes.ADCSM,
    OrderCodes.NORM_CGM_THIRD,
    OrderCodes.CM,
    OrderCodes.RPSM,
  ],
  [OrderGroups.SUSPENSION_RECONNECTION]: [OrderCodes.RSE, OrderCodes.SSE, OrderCodes.SSSE],
  [OrderGroups.CANCELATION]: [OrderCodes.REMOV_CE_NO_INST, OrderCodes.CC],
};

export const DATASETS: ChartDatasets[] = [
  {
    label: "Instalación",
    dataKey: OrderGroups.INSTALLATION,
    backgroundColor: "#7D3EFB",
  },
  {
    label: "Inspección",
    dataKey: OrderGroups.INSPECTION,
    backgroundColor: "#3892F3",
  },
  {
    label: "Normalización",
    dataKey: OrderGroups.NORMALIZATION,
    backgroundColor: "#12B76A",
  },
  {
    label: "Suspensión/Reconexión",
    dataKey: OrderGroups.SUSPENSION_RECONNECTION,
    backgroundColor: "#F79009",
  },
  {
    label: "Cancelación",
    dataKey: OrderGroups.CANCELATION,
    backgroundColor: "#F04438",
  },
];

export const CALENDAR_EVENTS_COLORS: Record<OrderStatus, { bgcolor: string; label: string }> = {
  pending: { bgcolor: "#F79009", label: "Pendiente" },
  done: { bgcolor: "#12B76A", label: "Completada" },
  cancel: { bgcolor: "#F04438", label: "Cancelada" },
  partial_done: { bgcolor: "#3B82F6", label: "Parcialmente completado" },
  pending_assignment: { bgcolor: "#3B82F6", label: "Parcialmente completado" },
};

export const AVERAGE_HOURS_VIEW_FILTERS: { label: string; type: AverageHoursViewType }[] = [
  { label: "Día", type: AverageHoursViewType.DAY },
  { label: "Mes", type: AverageHoursViewType.MONTH },
];

export const ORDER_ENTITIES_NAMES: Record<OrderEntityCode, string> = {
  LD: "Lead",
  MP: "Punto de medida",
  SMB: "smartBit",
};
