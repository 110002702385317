import { createAsyncThunk } from "@reduxjs/toolkit";
import { geoPoliticsApi, getOperatorData } from "../../services";
import { GeopoliticsResponse } from "../../models/geopolitics";
import { GetCalendarOrdersParams, OrderParams } from "../../models/calendar";
import { RootState } from "../store";
import moment from "moment";
import { setError, setEvents, setLoading, updateMothsFetched } from "../slices/calendar";
import { DATE_TIME_FORMAT } from "../../constants/orders";
import { Orders } from "../../models/orders";
import { formatCalendarEvents } from "../../helpers";
import { getAcessToken } from "../../helpers/token";
import { initializeEventSource } from "../../helpers/eventSource";

export const getStatesList = createAsyncThunk("calendar/getStatesList", async () => {
  try {
    const { data } = await geoPoliticsApi.get<GeopoliticsResponse>(`/states/?country_code=co`);
    const listState = data.items.map((state) => {
      return state.name;
    });
    return listState;
  } catch (error: any) {
    return [];
  }
});

export const getOrders = createAsyncThunk(
  "calendar/getOrders",
  async ({ date, email, state, isNavigate }: GetCalendarOrdersParams, { dispatch, getState }) => {
    const _state = getState() as RootState;
    const { monthsFetched } = _state.calendar;

    if (isNavigate && monthsFetched.includes(`${moment(date).format("MM-YYYY")}`)) {
      return;
    }

    dispatch(updateMothsFetched(date));

    dispatch(setError(undefined));
    dispatch(setLoading(true));

    const planned_date_begin = moment(date).startOf("month").format(DATE_TIME_FORMAT);
    const planned_date_end = moment(date).endOf("month").format(DATE_TIME_FORMAT);

    const params: OrderParams = {
      planned_date_begin,
      planned_date_end,
    };

    if (email) {
      try {
        const opertator = await getOperatorData(email);
        if (!opertator) {
          dispatch(setError(`No se encontró el operador con correo ${email}`));
          return;
        }
        params.operator_id = opertator.id;
      } catch (error) {
        dispatch(setError(`No se encontró el operador con correo ${email}`));
        return;
      }
    }

    if (state.length > 0) {
      params.state = state.join(",");
    }

    const token = getAcessToken();

    const itemsArray = (await initializeEventSource(
      token,
      dispatch,
      params,
      date,
      isNavigate,
      false
    )) as Orders[];

    return itemsArray;
  }
);
