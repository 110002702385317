import { Box, SxProps, Typography, styled } from "@enerbit/base";
import { EventProps } from "react-big-calendar";
import { CALENDAR_EVENTS_COLORS } from "../../constants/orders";
import { OrderStatus } from "../../models/orders";

const CustomCalendarEvent = ({ title, event }: EventProps) => {
  const eventStyles: SxProps = { bgcolor: "#F2F4F7", color: "#667085 !important" };

  return (
    <Box sx={eventStyles}>
      <Tag bgcolor={CALENDAR_EVENTS_COLORS[event.resource.order.status as OrderStatus].bgcolor}>
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: "10px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            color: "white",
          }}>
          {CALENDAR_EVENTS_COLORS[event.resource.order.status as OrderStatus].label}
        </Typography>
      </Tag>
      <Typography
        sx={{
          fontWeight: 700,
          fontSize: "12px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}>
        {title}
      </Typography>
    </Box>
  );
};

export default CustomCalendarEvent;

type TagProps = { bgcolor: string };

const Tag = styled(Box)<TagProps>(({ bgcolor }) => ({
  borderRadius: "4px",
  color: "#fff",
  padding: "1px 5px",
  backgroundColor: bgcolor,
  maxWidth: "200px",
}));
