import { useEffect } from "react";
import { Box, CustomAlert, Typography } from "@enerbit/base";
import OrdersCalendar from "../../../components/OrdersCalendar/OrdersCalendar";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { assignedOrdersCalendar } from "../../../constants/validations";
import { CalendarFormData } from "../../../models/orders";
import CalendarSearch from "../../../components/OrdersCalendar/CalendarSearch";
import moment from "moment";
import { DATE_TIME_FORMAT } from "../../../constants/orders";
import { AppDispatch, RootState } from "../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { getOrders, getStatesList } from "../../../store/thunks/calendar";
import {
  resetMonthsFetched,
  resetOrders,
  setError,
  setEvents,
  setLoading,
} from "../../../store/slices/calendar";

const LOCAL_MONTHS_FETCHED_KEY = "calendar_months_fetched";

type CustomEvents = "orders" | "end";

const AssignedOrders = () => {
  const { monthsFetched, error } = useSelector((state: RootState) => state.calendar);

  const dispatch = useDispatch<AppDispatch>();

  const form = useForm<CalendarFormData>({
    resolver: yupResolver(assignedOrdersCalendar),
    defaultValues: { email: "", state: [] },
  });

  const { reset } = form;

  const onSubmit: SubmitHandler<CalendarFormData> = async (data) => {
    dispatch(resetMonthsFetched());
    dispatch(setEvents([]));
    dispatch(resetOrders());
    dispatch(
      getOrders({ email: data.email, state: data.state, date: moment().format(DATE_TIME_FORMAT) })
    );
  };

  const handleRefreshCalendar = () => {
    reset();
    dispatch(resetMonthsFetched());
    dispatch(setEvents([]));
    dispatch(resetOrders());
    dispatch(setError(undefined));
    dispatch(setLoading(false));
  };

  useEffect(() => {
    dispatch(resetMonthsFetched());
    dispatch(getStatesList());
  }, []);

  return (
    <Box sx={{ pb: 10 }}>
      <Typography color='primary' sx={{ fontWeight: 700, fontSize: "22px" }}>
        Órdenes asignadas
      </Typography>
      <Typography my={2} sx={{ fontSize: "16px" }}>
        Puedes buscar por correo del operador o región.
      </Typography>
      <CalendarSearch form={form} onSubmit={onSubmit} onReset={handleRefreshCalendar} />

      {error && (
        <Box my={1}>
          <CustomAlert severity='error' text={error} onClose={() => setError(undefined)} />
        </Box>
      )}
      {monthsFetched.length > 0 ? <OrdersCalendar form={form} /> : null}
    </Box>
  );
};

export default AssignedOrders;
