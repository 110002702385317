import { Box } from "@enerbit/base";
import moment from "moment";
import {
    Calendar,
    Event,
    NavigateAction,
    View,
    momentLocalizer,
} from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import CustomCalendarToolbar from "./CustomCalendarToolbar";
import "./OrdersCalendar.css";
import { useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { DATE_TIME_FORMAT } from "../../constants/orders";
import { formatCalendarEvents, getEventStyles } from "../../helpers";
import { CalendarFormData } from "../../models/orders";
import { AppDispatch, RootState } from "../../store/store";
import { getOrders } from "../../store/thunks/calendar";
import CalendarLoader from "./CalendarLoader";
import CustomCalendarEvent from "./CustomCalendarEvent";
import EventModal from "./EventModal/EventModal";

moment.locale("es");
const localizer = momentLocalizer(moment);

interface Props {
    form: UseFormReturn<CalendarFormData, any, undefined>;
}

const OrdersCalendar = ({ form }: Props) => {
    const [openModal, setOpenModal] = useState<boolean>(true);
    const [selectedEvent, setSelectedEvent] = useState<Event | undefined>(
        undefined,
    );

    const { loading, events, orders } = useSelector(
        (state: RootState) => state.calendar,
    );

    const dispatch = useDispatch<AppDispatch>();

    const onNavigate = async (
        newDate: Date,
        view: View,
        action: NavigateAction,
    ) => {
        const values = form.getValues();

        dispatch(
            getOrders({
                email: values.email,
                state: values.state,
                date: moment(newDate).format(DATE_TIME_FORMAT),
                isNavigate: true,
            }),
        );
    };

    const handleEventClick = (event: Event) => {
        setSelectedEvent(event);
        setOpenModal(true);
    };

    const handleModalClose = () => {
        setSelectedEvent(undefined);
        setOpenModal(false);
    };

    return (
        <Box sx={{ height: "1200px", position: "relative" }}>
            {/* @ts-ignore */}
            <Calendar
                localizer={localizer}
                defaultDate={new Date()}
                defaultView={"month"}
                components={{
                    toolbar: CustomCalendarToolbar,
                    event: CustomCalendarEvent,
                }}
                events={formatCalendarEvents(orders)}
                dayLayoutAlgorithm={"no-overlap"}
                startAccessor="start"
                endAccessor="end"
                eventPropGetter={(event) => getEventStyles(event)}
                onNavigate={onNavigate}
                onSelectEvent={handleEventClick}
            />
            {selectedEvent && (
                <EventModal
                    handleClose={handleModalClose}
                    event={selectedEvent}
                    open={openModal}
                />
            )}
            {loading && <CalendarLoader />}
        </Box>
    );
};

export default OrdersCalendar;
