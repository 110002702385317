import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  LinearProgress,
  Typography,
  enerbitColors,
  CachedIcon,
  SxProps,
} from "@enerbit/base";
import moment from "moment";
import { getOrdersByStatus } from "../../services";
import { useEffect, useState } from "react";
import { DATE_TIME_FORMAT } from "../../constants/orders";
interface IOrdersProgress {
  name: string;
  quantity: number;
}

const OrderProgress = () => {
  const [resultOrders, setResultOrders] = useState<IOrdersProgress[]>();
  const [totalOrders, setTotalOrders] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const fecthOrders = async () => {
    setError(false);
    setLoading(true);
    try {
      const since = moment().startOf("day").format(DATE_TIME_FORMAT);
      const until = moment().endOf("day").format(DATE_TIME_FORMAT);

      const tOrders = await getOrdersByStatus(since, until);

      const data: IOrdersProgress[] = [
        { name: "Completadas", quantity: tOrders.length > 0 ? tOrders[0].total_done_orders : 0 },
        { name: "Pendientes", quantity: tOrders.length > 0 ? tOrders[0].total_pending_orders : 0 },
        {
          name: "Canceladas",
          quantity: tOrders.length > 0 ? tOrders[0].total_cancelled_orders : 0,
        },
      ];

      setResultOrders(() => data);
      setTotalOrders(tOrders.length > 0 ? tOrders[0].total_orders : 0);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const getProgressColor = (): string => {
    if (!resultOrders) return "success";

    const completedPercentage =
      totalOrders > 0 ? (resultOrders[0].quantity / totalOrders) * 100 : 0;
    if (completedPercentage === 100) {
      return "success";
    } else if (completedPercentage >= 75) {
      return "secondary";
    } else if (completedPercentage >= 30 && completedPercentage < 75) {
      return "warning";
    } else if (completedPercentage === 0) {
      return "inherit";
    } else {
      return "error";
    }
  };

  const refreshButtonStyles: SxProps = {
    minHeight: "unset !important",
    width: "20px",
    height: "20px",
    borderRadius: "100% !important",
    minWidth: "unset !important",
    padding: "16px !important",
  };

  useEffect(() => {
    fecthOrders();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        gap: 2,
        backgroundColor: enerbitColors.neutral[100],
        borderRadius: "8px",
        padding: "24px",
        height: "100%",
      }}>
      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <CircularProgress />
        </Box>
      )}
      {error && (
        <Box>
          <Alert severity='error'>Error consultando los datos.</Alert>
        </Box>
      )}
      {!loading && !error && resultOrders && (
        <>
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}>
              <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
                Progreso de actividades
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 1,
                }}>
                <Typography sx={{ fontSize: "16px" }}>
                  {resultOrders[0].quantity}/{totalOrders}
                </Typography>
                <Button variant='contained' sx={refreshButtonStyles} onClick={fecthOrders}>
                  <CachedIcon />
                </Button>
              </Box>
            </Box>
            <Box sx={{ mt: 1 }}>
              <LinearProgress
                variant='determinate'
                value={totalOrders > 0 ? (resultOrders[0].quantity / totalOrders) * 100 : 0}
                color={getProgressColor() as "success"}
                sx={{ height: "20px", borderRadius: "8px" }}
              />
            </Box>
          </Box>

          <Grid container columnSpacing={2} rowSpacing={1}>
            {resultOrders.map((item, index) => (
              <Grid key={index} item md={4} xs={4}>
                <Box
                  sx={{
                    textAlign: "center",
                    border: `1px solid ${enerbitColors.primary.main}`,
                    borderRadius: "8px",
                    py: 2,
                  }}>
                  <Typography
                    color='primary'
                    sx={{
                      fontWeight: "bold",
                      fontSize: "24px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}>
                    {item.quantity}
                  </Typography>
                  <Typography
                    color='primary'
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}>
                    {item.name}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </Box>
  );
};

export default OrderProgress;
