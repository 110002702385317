import { ThemeConfig, initEnviroment } from "@enerbit/base";
import { hot } from "react-hot-loader/root";
import { Provider } from "react-redux";
import { store } from "./store/store";
import Overview from "./pages/Overview/Overview";

initEnviroment({
  baseUrl: process.env.REACT_APP_BASE_URL,
  geopoliticsBaseUrl: process.env.REACT_APP_BASE_GEOPOLITICS_URL,
});

export default hot(function Root() {
  return (
    <Provider store={store}>
      <ThemeConfig>
        <Overview />
      </ThemeConfig>
    </Provider>
  );
});
