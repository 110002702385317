import { createSlice } from "@reduxjs/toolkit";
import { ClientsState } from "../../models/clients";
import { getClientMetrics } from "../thunks/clients";

const initialState: ClientsState = {
  loading: false,
  error: false,
  metrics: null,
  installedClientsGoal: 20000,
};

export const clientsSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getClientMetrics.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.metrics = null;
    });
    builder.addCase(getClientMetrics.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.metrics = payload;
    });
    builder.addCase(getClientMetrics.rejected, (state) => {
      state.loading = false;
      state.error = true;
      state.metrics = null;
    });
  },
});

export const {} = clientsSlice.actions;
