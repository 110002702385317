import { Box, Button, Typography, enerbitColors, styled } from "@enerbit/base";
import { OrderEntityCode, OrderEventDetail, OrderStatus } from "../../../models/orders";
import { Event } from "react-big-calendar";
import { useEffect, useState } from "react";
import { CALENDAR_EVENTS_COLORS } from "../../../constants/orders";

interface Props {
  event: Event;
  details: OrderEventDetail;
}

const ClientsData = ({ event, details }: Props) => {
  const entityCode =
    event.resource.order.order_entities.length > 0
      ? event.resource.order.order_entities[0].entity.code
      : "Sin entidad";
  const [showAll, setShowAll] = useState<boolean>(true);
  const [entities, setEntities] = useState<
    {
      id: string;
      email?: string | undefined;
      address: string;
      fullName?: string | undefined;
      status: string;
    }[]
  >(details.entitie);

  const onShowAll = () => {
    setShowAll(!showAll);
    setEntities(showAll ? details.entitie : details.entitie.slice(0, 3));
  };

  useEffect(() => {
    if (details.entitie.length > 3) {
      setEntities(details.entitie.slice(0, 3));
    }
  }, []);

  return (
    <Box my={1}>
      <Typography color='primary' sx={{ fontSize: "18px" }}>
        {entityCode === OrderEntityCode.LD ? "Información clientes" : "Direcciones"}
      </Typography>
      {entities.map((entity, index) => (
        <Box key={entity.id}>
          {entityCode === OrderEntityCode.LD ? (
            <Box mb={1}>
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  justifyContent: "space-between",
                  alignItems: "center",
                }}>
                <Typography
                  sx={{ color: enerbitColors.neutral.main, fontWeight: 700, fontSize: "16px" }}>
                  {entity.fullName}
                </Typography>
                <Tag bgcolor={CALENDAR_EVENTS_COLORS[entity.status as OrderStatus].bgcolor}>
                  {CALENDAR_EVENTS_COLORS[entity.status as OrderStatus].label}
                </Tag>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Typography sx={{ color: enerbitColors.neutral.main }}>{entity.email}</Typography>
                <Typography sx={{ color: enerbitColors.neutral.main, textAlign: "center" }}>
                  |
                </Typography>
                <Typography sx={{ color: enerbitColors.neutral.main }}>{entity.address}</Typography>
              </Box>
            </Box>
          ) : (
            <Box mb={1}>
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  justifyContent: "space-between",
                  alignItems: "center",
                }}>
                <Typography
                  sx={{ color: enerbitColors.neutral.main, fontWeight: 700, fontSize: "16px" }}>
                  Id: {entity.id}
                </Typography>
                <Tag bgcolor={CALENDAR_EVENTS_COLORS[entity.status as OrderStatus].bgcolor}>
                  {CALENDAR_EVENTS_COLORS[entity.status as OrderStatus].label}
                </Tag>
              </Box>
              <Typography sx={{ color: enerbitColors.neutral.main }}>{entity.address}</Typography>
            </Box>
          )}
        </Box>
      ))}
      {details.entitie.length > 3 ? (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Button
            variant='text'
            color='secondary'
            onClick={(e) => {
              e.stopPropagation();
              onShowAll();
            }}>
            {showAll ? "Ver todo" : "Ocultar"}
          </Button>
        </Box>
      ) : null}
    </Box>
  );
};

export default ClientsData;

type TagProps = { bgcolor: string };

const Tag = styled(Box)<TagProps>(({ bgcolor }) => ({
  borderRadius: "4px",
  color: "#fff",
  padding: "1px 5px",
  backgroundColor: bgcolor,
  maxWidth: "200px",
  height: "23px",
  textAlign: "center",
  fontWeight: 700,
}));
