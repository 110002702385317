import { Box, Button, CloseIcon, Typography, styled } from "@enerbit/base";
import { Event } from "react-big-calendar";
import { CALENDAR_EVENTS_COLORS } from "../../../constants/orders";
import { OrderStatus } from "../../../models/orders";

interface Props {
  event: Event;
  handleClose: () => void;
}

const ModalHeader = ({ event, handleClose }: Props) => {
  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: 1 }}>
        <Typography color={"primary"} sx={{ fontSize: "21px" }}>
          Detalle de la orden
        </Typography>
        <Button onClick={handleClose} variant='outlined' className='back-btn'>
          <CloseIcon />
        </Button>
      </Box>
      <Typography color={"primary"} sx={{ fontSize: "24px", fontWeight: 700 }}>
        {event.title}
      </Typography>
      <Tag bgcolor={CALENDAR_EVENTS_COLORS[event.resource.order.status as OrderStatus].bgcolor}>
        {CALENDAR_EVENTS_COLORS[event.resource.order.status as OrderStatus].label}
      </Tag>
    </>
  );
};

export default ModalHeader;

type TagProps = { bgcolor: string };

const Tag = styled(Box)<TagProps>(({ bgcolor }) => ({
  borderRadius: "4px",
  color: "#fff",
  padding: "1px 5px",
  backgroundColor: bgcolor,
  maxWidth: "200px",
  textAlign: "center",
}));
