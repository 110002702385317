import { api } from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { CertificateTypesResponse, TotalCertificatesResponse } from "../../models/certificates";
import { ClientMetrics, ESSResponse } from "../../models/clients";

export const getClientMetrics = createAsyncThunk(
  "clients/getClienMetrics",
  async (_, { rejectWithValue }) => {
    try {
      // 1. Get certificates types filter by instalation
      const certificateTypeId = await api.get<CertificateTypesResponse>(
        "/service-orders-certificates/certificate-types?code=INST&page=0&size=50"
      );

      // 2. Get total installed
      const installations = await api.get<TotalCertificatesResponse>(
        `/service-orders-certificates/certificates/totals/by-type?certificate_type_id=${certificateTypeId.data.items[0].id}`
      );

      // 3. Get total billables
      const totalBillables = await api.get<ESSResponse>(
        "/assignees/ess?has_ended_at=false&page=0&size=1"
      );

      const meterics: ClientMetrics = {
        currentBillableClients: totalBillables.data.total,
        currentInstalledClients: installations.data.total,
        noCurrentBillableClients: installations.data.total - totalBillables.data.total,
      };

      return meterics;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
