import { Button, ExpandMoreIcon } from "@enerbit/base";

interface Props {
  expanded: boolean;
}

const AccordionButton = ({ expanded }: Props) => {
  return (
    <Button
      color='primary'
      variant='outlined'
      sx={{
        borderRadius: "12px !important",
        height: "32px",
        minWidth: "unset !important",
        display: "flex",
        transform: "none !important",
        width: "48px !important",
      }}>
      <ExpandMoreIcon
        sx={{ transform: expanded ? "rotate(180deg) !important" : "none !important" }}
      />
    </Button>
  );
};

export default AccordionButton;
