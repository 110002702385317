import { Event } from "react-big-calendar";
import {
  Inconsistencies,
  InconsistencyTypes,
  OrderEventDetail,
  OrderStatus,
} from "../../../models/orders";
import { Box, enerbitColors, FormControl, Grid, styled, Typography } from "@enerbit/base";
import moment from "moment";
import CustomTextField from "../../CustomTextField/CustomTextField";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { generateRandomId } from "../../../helpers";

interface Props {
  event: Event;
  details: OrderEventDetail;
}

const inconsistencyNames: Record<InconsistencyTypes, string> = {
  different_location: "Orden cerrada en una locación diferente",
  exceeded_time: "Tiempo de ejecución de la orden excedido",
  late_start: "Orden iniciada fuera de la hora programada",
};

const OrdersForm = ({ event, details }: Props) => {
  const formatInconsistencies = (inconsistencies: Inconsistencies[]) => {
    let text = "";
    inconsistencies.forEach((item) => {
      const formated = `Tipo: ${inconsistencyNames[item.inconsistency_type]}. Razón: ${
        item.reason
      }\n\n`;

      text += formated;
    });
    return text;
  };
  return (
    <>
      {event.resource.order.status === OrderStatus.DONE && (
        <Box my={1} sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <FormControl fullWidth>
            <Typography sx={{ color: "#3D4350", fontSize: "16px", mb: 1 }}>
              Finalizada por
            </Typography>
            <CustomTextField disabled={true} value={details.operator.pii.full_name} />
          </FormControl>
          <FormControl fullWidth sx={{ mt: 1 }}>
            <Grid container columnSpacing={2}>
              <Grid item xs={6}>
                <Typography sx={{ color: "#3D4350", fontSize: "16px", mb: 1 }}>
                  Fecha de inicio de la orden
                </Typography>
                <CustomTextField
                  fullWidth
                  disabled={true}
                  InputProps={{ endAdornment: <AccessTimeIcon /> }}
                  value={moment(event.resource.order.order_done.form_data.metric.started_at)
                    .utcOffset("-05:00")
                    .format("DD-MM-YYYY HH:mm")}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ color: "#3D4350", fontSize: "16px", mb: 1 }}>
                  Fecha de finalización de la orden
                </Typography>
                <CustomTextField
                  fullWidth
                  InputProps={{ endAdornment: <AccessTimeIcon /> }}
                  disabled={true}
                  value={moment(event.resource.order.order_done.form_data.metric.ended_at)
                    .utcOffset("-05:00")
                    .format("DD-MM-YYYY HH:mm")}
                />
              </Grid>
            </Grid>
          </FormControl>
          {event.resource.order.order_done.form_data.inconsistencies && (
            <Box sx={{ mt: 1 }}>
              <Typography sx={{ color: enerbitColors.primary.main, fontSize: "16px", mb: 1 }}>
                Imprevistos
              </Typography>
              {event.resource.order.order_done.form_data.inconsistencies.map(
                (item: Inconsistencies) => (
                  <InconsistenciesBox mb={1} key={generateRandomId()}>
                    <Typography sx={{ color: enerbitColors.neutral.main }}>
                      Tipo: <strong>{inconsistencyNames[item.inconsistency_type]}</strong>
                    </Typography>
                    <Typography sx={{ color: enerbitColors.neutral.main }}>
                      Razón: <strong>{item.reason}</strong>
                    </Typography>
                  </InconsistenciesBox>
                )
              )}
            </Box>
          )}
        </Box>
      )}
      {event.resource.order.status === OrderStatus.CANCEL && (
        <Box my={1} sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <FormControl fullWidth>
            <Typography sx={{ color: "#3D4350", fontSize: "16px", mb: 1 }}>
              Cancelada por:
            </Typography>
            <CustomTextField disabled={true} value={details.operator.pii.full_name} />
          </FormControl>
          <FormControl fullWidth>
            <Typography sx={{ color: "#3D4350", fontSize: "16px", mb: 1 }}>
              Motivo de la cancelación:
            </Typography>
            <CustomTextField
              disabled={true}
              value={event.resource.order.order_cancel.cancellation_reason.name}
            />
          </FormControl>
          <FormControl fullWidth>
            <Typography sx={{ color: "#3D4350", fontSize: "16px", mb: 1 }}>
              Observaciones:
            </Typography>
            <CustomTextField
              disabled={true}
              multiline
              rows={4}
              value={event.resource.order.order_cancel.observations}
            />
          </FormControl>
          {details.picture && (
            <Box sx={{ width: "100%" }}>
              <Typography sx={{ color: "#3D4350", fontSize: "16px", mb: 1 }}>
                Registro fotográfico:
              </Typography>
              <img
                style={{ width: "100%", height: "200px" }}
                src={details.picture}
                alt='Registro fotográfico'
              />
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default OrdersForm;

const InconsistenciesBox = styled(Box)(() => ({
  borderRadius: "16px",
  border: `1px solid ${enerbitColors.neutral[300]}`,
  padding: "12px",
}));
