import { configureStore } from "@reduxjs/toolkit";
import { calendarSlice } from "./slices/calendar";
import { clientsSlice } from "./slices/clients";

export const store = configureStore({
  reducer: {
    calendar: calendarSlice.reducer,
    clients: clientsSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
