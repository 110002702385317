import { Box } from "@enerbit/base";
import moment from "moment";
import { Calendar, Event, NavigateAction, View, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import CustomCalendarToolbar from "./CustomCalendarToolbar";
import "./OrdersCalendar.css";
import { formatCalendarEvents, getEventStyles } from "../../helpers";
import { CalendarFormData } from "../../models/orders";
import { UseFormReturn } from "react-hook-form";
import { DATE_TIME_FORMAT } from "../../constants/orders";
import CustomCalendarEvent from "./CustomCalendarEvent";
import { useState } from "react";
import EventModal from "./EventModal/EventModal";
import CalendarLoader from "./CalendarLoader";
import { AppDispatch, RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { getOrders } from "../../store/thunks/calendar";

moment.locale("es");
const localizer = momentLocalizer(moment);

interface Props {
  form: UseFormReturn<CalendarFormData, any, undefined>;
}

const OrdersCalendar = ({ form }: Props) => {
  const [openModal, setOpenModal] = useState<boolean>(true);
  const [selectedEvent, setSelectedEvent] = useState<Event | undefined>(undefined);

  const { loading, events, orders } = useSelector((state: RootState) => state.calendar);

  const dispatch = useDispatch<AppDispatch>();

  const onNavigate = async (newDate: Date, view: View, action: NavigateAction) => {
    const values = form.getValues();
    console.log("values", values);
    console.log("newDate", moment(newDate).startOf("month").format(DATE_TIME_FORMAT));
    dispatch(
      getOrders({
        email: values.email,
        state: values.state,
        date: moment(newDate).format(DATE_TIME_FORMAT),
        isNavigate: true,
      })
    );
  };

  const handleEventClick = (event: Event) => {
    setSelectedEvent(event);
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setSelectedEvent(undefined);
    setOpenModal(false);
  };

  return (
    <Box sx={{ height: "1200px", position: "relative" }}>
      <Calendar
        localizer={localizer}
        defaultDate={new Date()}
        defaultView={"month"}
        components={{
          toolbar: CustomCalendarToolbar,
          event: CustomCalendarEvent,
        }}
        events={formatCalendarEvents(orders)}
        dayLayoutAlgorithm={"no-overlap"}
        startAccessor='start'
        endAccessor='end'
        eventPropGetter={(event) => getEventStyles(event)}
        onNavigate={onNavigate}
        onSelectEvent={handleEventClick}
      />
      {selectedEvent && (
        <EventModal handleClose={handleModalClose} event={selectedEvent} open={openModal} />
      )}
      {loading && <CalendarLoader />}
    </Box>
  );
};

export default OrdersCalendar;
